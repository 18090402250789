import {
    FETCH_ALL_CATEGORIES,
    CREATE_CATEGORIES,
    UPDATE_CATEGORIES,
    DELETE_CATEGORIES,
} from "../constants/actionTypes";
import {
    getAll,
    store,
    updateCategoriesApi,
    deleteCategoriesApi
} from "../api/categories";

export const getCategories = () => async (dispatch) => {
    try {
        const {data} = await getAll();
        dispatch({type: FETCH_ALL_CATEGORIES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const createCategories = (post) => async (dispatch) => {
    try {
        const {data} = await store(post);

        dispatch({type: CREATE_CATEGORIES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const updateCategories = (id, post) => async (dispatch) => {
    try {
        const {data} = await updateCategoriesApi(id, post);

        dispatch({type: UPDATE_CATEGORIES, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const deleteCategories = (id) => async (dispatch) => {
    try {
        await deleteCategoriesApi(id);

        dispatch({type: DELETE_CATEGORIES, payload: id});
    } catch (error) {
        console.log(error);
    }
};