export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const START_AUTH = "START_AUTH"
export const FETCH_ALL_VIDEO = "FETCH_ALL_VIDEO"
export const CREATE_VIDEO = "CREATE_VIDEO"
export const DELETE_VIDEO = "DELETE_VIDEO"
export const UPDATE_VIDEO = "UPDATE_VIDEO"
export const FETCH_ALL_ARTICLES = "FETCH_ALL_ARTICLES"
export const CREATE_ARTICLES = "CREATE_ARTICLES"
export const DELETE_ARTICLES = "DELETE_ARTICLES"
export const UPDATE_ARTICLES = "UPDATE_ARTICLES"
export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES"
export const CREATE_CATEGORIES = "CREATE_CATEGORIES"
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"
export const DELETE_CATEGORIES = "DELETE_CATEGORIES"
export const GET_ONE_ARTICLE = "GET_ONE_ARTICLE"
export const GET_AGES = "GET_AGES"
export const GET_TAGS = "GET_TAGS"
export const UPDATE_AGES = "GET_AGES"
export const UPDATE_TAGS = "GET_TAGS"
export const CREATE_AGES = "GET_AGES"
export const CREATE_TAGS = "GET_TAGS"
export const POP_UP = "POP_UP"
export const POP_UP_BG = "POP_UP_BG"
export const POP_UP_VIDEO = "POP_UP_VIDEO"
export const EDIT = "EDIT"
export const GET_ONE_VIDEO = "GET_ONE_VIDEO"
export const DELETE_TAG = "DELETE_TAG"
export const SET_CATEGORY_NAME = "SET_CATEGORY_NAME"
