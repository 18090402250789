import {
    FETCH_ALL_VIDEO,
    CREATE_VIDEO,
    UPDATE_VIDEO,
    DELETE_VIDEO,
    GET_ONE_VIDEO, SET_CATEGORY_NAME,
} from "../constants/actionTypes";
import {
    getAll,
    store,
    updateVideoApi,
    deleteVideoApi,
    getOneVideoApi, getCategoryCount,
} from "../api/videos";

export const getVideos = (size, page, category) => async (dispatch) => {
    try {
        // console.log(await getAll(size, page, category));
        const {data} = await getAll(size, page, category);
        // console.log(data);
        dispatch({type: FETCH_ALL_VIDEO, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const getOneVideo = (size) => async (dispatch) => {
    try {
        const {data} = await getOneVideoApi(size);
        console.log(data)
        dispatch({type: GET_ONE_VIDEO, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const createVideos = (post) => async (dispatch) => {
    try {
        const {data} = await store(post);

        dispatch({type: CREATE_VIDEO, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const updateVideos = (id, post) => async (dispatch) => {
    try {
        const {data} = await updateVideoApi(id, post);

        dispatch({type: UPDATE_VIDEO, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const deleteVideos = (id) => async (dispatch) => {
    try {
        await deleteVideoApi(id);

        dispatch({type: DELETE_VIDEO, payload: id});
    } catch (error) {
        console.log(error);
    }
};

export const setCategoryNames = () => async (dispatch) => {
    try {
        const {data} = await getCategoryCount()
        console.log(data)
        dispatch({type:SET_CATEGORY_NAME, payload:data})
    } catch (error) {
        console.error(error);
    }
}