import axios from "./index";

export const getAll = (size,page) => axios.get(`articles?page=${page}&size=${size}&sort=id, ASC`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
        withCredentials: true
});
export const getOneArticleApi = (size) => axios.get(`articles/${size}`,{headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const store = (body) => axios.post('articles',body);
export const updateArticlesApi = (id,body) => axios.put(`articles/${id}`,body);
export const deleteArticlesApi = (id) => axios.delete(`articles/delete/${id}`);

