import {
    FETCH_ALL_ARTICLES,
    CREATE_ARTICLES,
    UPDATE_ARTICLES,
    DELETE_ARTICLES,
} from '../../constants/actionTypes';

const initialState = {
    articles : undefined,
    pag:0
};


export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ARTICLES:
            return {
                ...state,
                articles: action.payload.content,
                pag: action.payload.totalPages
            };
        case CREATE_ARTICLES:
            return {
                ...state,
                articles : [...state.articles, action.payload.content],
            };
        case UPDATE_ARTICLES:
            return {
                ...state,
                articles : state.articles.map((article) => (article._id === action.payload._id ? action.payload : article)),
            };
        case DELETE_ARTICLES:
            return {
                ...state,
                articles : state.articles.filter((article) => article.id !== action.payload),
            };
        default:
            return state;
    }
};