import axios from "./index";

export const getAll = async (size, page, category) => {
    return axios.get(`courses?page=${page}&size=${size}${category !== "" ? `&categoryName=${category}` : ""}&sort=id, ASC`, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
}
 export const store = (body) => axios.post('media/video/upload',body,
    {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data boundary=${form._boundary}'
        }}
    );
export const updateVideoApi = (id,body) => axios.put(`courses/${id}`,body,
    {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}}
    );
export const deleteVideoApi = (id) => axios.delete(`courses/delete/${id}`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});

export const getOneVideoApi = (size) => axios.get(`courses/${size}`,{headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const getCategoryCount = () => {
    return axios.get(`categories/count`, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}}).then(res => {
        console.log(res)
        return res;
    }).catch(e => {
        console.log("xato", e)
    })
}
