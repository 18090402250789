import {
    GET_TAGS,
    GET_AGES,
    UPDATE_TAGS,
    UPDATE_AGES,
    CREATE_AGES,
    CREATE_TAGS,
    DELETE_TAG,
} from "../constants/actionTypes";
import {
    getTags,
    getAges,
    editTags,
    editAges,
    storeTags,
    storeAges, deleteTags
} from "../api/params";

export const getTagsParams = () => async (dispatch) => {
    try {
        const {data} = await getTags();
        dispatch({type: GET_TAGS, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const getAgesParams = () => async (dispatch) => {
    try {
        const {data} = await getAges();
        dispatch({type: GET_AGES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const createTagsParams = (post) => async (dispatch) => {
    try {
        const {data} = await storeTags(post);

        dispatch({type: CREATE_TAGS, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const createAgesParams = (post) => async (dispatch) => {
    try {
        const {data} = await storeAges(post);

        dispatch({type: CREATE_AGES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const updateTagsParams = (id, post) => async (dispatch) => {
    try {
        const {data} = await editTags(id, post);

        dispatch({type: UPDATE_TAGS, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const updateAgesParams = (id, post) => async (dispatch) => {
    try {
        const {data} = await editAges(id, post);

        dispatch({type: UPDATE_AGES, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const deleteTagParams = (id) => async (dispatch) => {
    try {
        await deleteTags(id);

        dispatch({type: DELETE_TAG, payload: id});
    } catch (error) {
        console.log(error);
    }
};