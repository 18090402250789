import {
    FETCH_ALL_VIDEO,
    CREATE_VIDEO,
    UPDATE_VIDEO,
    DELETE_VIDEO, POP_UP_VIDEO, SET_CATEGORY_NAME,
} from '../../constants/actionTypes';

const initialState = {
    videos: [],
    oneVideo: [],
    editVideoId: undefined,
    categoryNames: [],
    pag: 0
};


export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_VIDEO:
            return {
                ...state,
                videos: action.payload.content,
                pag: action.payload.totalPages
            };
        case POP_UP_VIDEO: {
            return {
                ...state,
                oneVideo: state.videos.filter(i => i.id === action.payload),
                editVideoId: action.payload
            }
        }
        case CREATE_VIDEO:
            return {
                ...state,
                videos: [...state.videos, action.payload]
            };
        case UPDATE_VIDEO:
            return {
                ...state,
                videos: state.videos.map((video) => (video._id === action.payload._id ? action.payload : video))
            }
        case DELETE_VIDEO:
            return {
                ...state,
                videos: state.videos.filter((video) => video.id !== action.payload)
            }
        case SET_CATEGORY_NAME:
            return {
                ...state,
                categoryNames: action.payload.map((item) => item.count > 0 ? {name: item.name, count:item.count} : null).filter(obj => obj ? obj : false)
            }
        default:
            return state;
    }
};