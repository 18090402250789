import axios from "axios";

export const MainApi = "https://farzandlarim.uz/admin/api";
export const MainWithoutApi = "https://farzandlarim.uz";
// export const MainApi = "http://192.168.43.82/api/v1";

export const GetToken = () => {
    return localStorage.getItem("token");
    // localStorage.setItem("token", "")
};

const instance = axios.create({
    withCredentials: true,
    baseURL: MainApi,
});

instance.interceptors.request.use((config) => {
    if (config.headers === undefined) {
      config.headers = {}
    }
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    return config;
  })

instance.interceptors.response.use((config) => {
    return config;
  }, (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  })

export default instance;