import {combineReducers} from "redux";
import videosMain from "./videos";
import auth from "./auth";
import articlesMain from "./articles"
import categories from "./categories";
import oneArt from "./oneArticle"
import tagsMain from "./tags"
import agesMain from "./ages"
import oneVideoMain from "./oneVideo"
import pagination from "./pagination";

export default combineReducers({
    videosMain,
    auth,
    articlesMain,
    categories,
    oneArt,
    tagsMain,
    agesMain,
    oneVideoMain,
    pagination
})