const VIDEO = "VIDEO";
const ARTICLE = "ARTICLE";


const initialState = {
    video: 0,
    article: 0,
};

const pagination = (state = initialState, action) => {
    switch (action.type) {
        case VIDEO:
            return {...state, video: action.payload};
        case ARTICLE:
            return {...state, article: action.payload};
        default:
            return state;
    }
};

export default pagination

export const pagVideo = (mes) => (dispatch) => {
    dispatch({type: VIDEO, payload: mes});
};
export const pagArticle = (mes) => (dispatch) => {
    dispatch({type: ARTICLE, payload: mes});
};