import React, {useEffect, useState} from 'react';
import './pop.scss'
import {connect} from "react-redux";
import {editHandle, popUpVideoHandle} from "../../actions/auth";
import htmlToDraft from "html-to-draftjs"
import {EditorState, ContentState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {useNavigate} from "react-router";
import {deleteVideos, getVideos} from "../../actions/videos";
import ReactPlayer from 'react-player'
import {MainWithoutApi} from "../../api";


function PopVideo({popVideoBool,popUpVideoHandle, deleteVideos, videos, getVideos,popId,pId}) {
    const [des, setDes] = useState("")
    const [articles, setArticles] = useState(undefined)

    const blocks = htmlToDraft(des);
    const {contentBlocks, entityMap} = blocks;
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );

    const editorState1 = EditorState.createWithContent(contentState);
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('/editvideo');
        popUpVideoHandle(popId);
    }
    const handleDelete = (id) => {
        deleteVideos(id);
        getVideos(10, pId);
        popUpVideoHandle(undefined)
    }
    useEffect(() => {
        if (videos !== undefined) {
            setArticles(videos);
        }
    }, [videos]);

    useEffect(() => {
        if (articles){
            if (articles.description !== undefined) {
                setDes(articles.description);
            }
        }
    }, [articles]);
    return (
        <div>
            {
                articles !== undefined ? <div className={`popVideo ${popVideoBool ? "ok" : ""}`}>
                        <div className={`back`}>
                            <div className="looper">
                                <div className="qw">{articles.id} - Article</div>
                                <div className="ooper">
                                    <div className="img"
                                         style={{backgroundImage: `url('${MainWithoutApi}/${articles.imageUrl}')`}}></div>
                                    {/*<div className="img"></div>*/}
                                    <div className="right">
                                        <div className="r1"> Title: <span>{articles.title}</span>
                                        </div>
                                        <div className="r1"> Subtitle: <span>{articles.subtitle}</span>
                                        </div>
                                        <div className="r1"> Category: <span>{articles.categoryName}</span></div>
                                        <div className="r1"> Age: <span>{articles.childCategoryName}</span></div>
                                        <div className="r1">
                                            <div>Free : <span>{articles.free === true ? "Yes" : "No"}</span></div>
                                            <div>Active: <span>{articles.active === true ? "Yes" : "No"}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="des">
                                    <div className="tit">Description:</div>
                                    <Editor
                                        className="edits"
                                        editorState={editorState1}
                                        toolbar={{
                                            options: [],
                                        }}
                                    />
                                </div>
                                <ReactPlayer controls={true} url={articles && articles.videoParams && articles.videoParams.link} />
                                {/*<div className="tgg">*/}
                                {/*    <div className="tit">Tags:</div>*/}
                                {/*    <div className="tags">*/}
                                {/*        {articles.tags && articles.tags.map((i, k) => {*/}
                                {/*            return (*/}
                                {/*                <div className="tgs">{i.name}</div>*/}
                                {/*            )*/}
                                {/*        })}*/}


                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="audi">*/}
                                {/*    <div className="tit">*/}
                                {/*        <div className="titi">*/}
                                {/*            Audio:*/}
                                {/*        </div>*/}

                                {/*        <audio controls type="audio/*"*/}
                                {/*               src={`http://67.207.85.66:8080/${articles.audioLink}`}>*/}

                                {/*        </audio>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="more">
                                    <div className="edit" onClick={() => handleEdit()}>
                                        edit
                                    </div>
                                    <div className="del" onClick={() => handleDelete(articles.id)}>
                                        delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    popVideoBool: state.auth.popVideo,
    popId: state.auth.popContent,
    articles: state.oneArt,
    videos: state.oneVideoMain,
    pId:state.pagination.video
});

export default connect(mapStateToProps, {popUpVideoHandle, editHandle, deleteVideos, getVideos})(PopVideo);
