import React, {useState} from 'react';
import Sidebar from "./sideabar/sidebar";
import Header from "./header/header";
import './index.scss'
import {connect} from "react-redux";
import Pop from "../pages/pop/pop";
import {popUpBgHandle, popUpHandle, popUpVideoHandle} from "../actions/auth";
import PopVideo from "../pages/videoPop/pop";
import {Spin} from "antd";

function Layout({children, popBool, popUpHandle, popVideoBool,popUpVideoHandle,popUpBgHandle,bgAll}) {
    const [bool, setBool] = useState(true);
    const style = {
        position: "fixed",
        height: "100vh",
        width: "100%",
        left: 0,
        top: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        display: `${popBool ? "block" : "none"}`,
        zIndex: 210
    }
    const style1 = {
        position: "fixed",
        height: "100vh",
        width: "100%",
        left: 0,
        top: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        display: `${popVideoBool ? "block" : "none"}`,
        zIndex: 210
    }
    const style3 = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.4)",
        display: `${bgAll ? "flex" : "none"}`,
        zIndex: 5000,
        justifyContent:"center",
        alignItems:"center"
    }
    return (
        <div>
            <div style={style} onClick={() => popUpHandle(undefined)}/>
            <div style={style1} onClick={() => popUpVideoHandle(undefined)}/>
            <div style={style3}>
                <Spin/>
            </div>
            <Pop/>
            <PopVideo/>
            <Header setBool={setBool} bool={bool}/>
            <Sidebar bool={bool}/>
            <div className={`layout ${!bool ? "cl" : ""}`}>
                {children}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    popBool: state.auth.artBool,
    popId: state.auth.artId,
    popVideoBool: state.auth.popVideo,
    bgAll:state.auth.bgAll
});

export default connect(mapStateToProps, {popUpHandle,popUpVideoHandle,popUpBgHandle})(Layout);