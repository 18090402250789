import React, {useEffect, useState} from 'react';
import './pop.scss'
import {connect} from "react-redux";
import {editHandle, popUpHandle} from "../../actions/auth";
import htmlToDraft from "html-to-draftjs"
import {EditorState, ContentState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {useNavigate} from "react-router";
import {deleteArticles, getArticles} from "../../actions/articles";
import {MainWithoutApi} from "../../api";


function Pop({popBool, articles, popUpHandle, popId, editHandle, deleteArticles,getArticles,pId}) {
    const [des, setDes] = useState("")

    const blocks = htmlToDraft(des);
    const {contentBlocks, entityMap} = blocks;
    const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
    );

    const editorState = EditorState.createWithContent(contentState);
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('/editarticle');
        popUpHandle(popId);
        editHandle();
    }
    const handleDelete = (id) => {
        deleteArticles(id);
        popUpHandle(undefined);
    }

    useEffect(() => {
        if (articles.description !== undefined) {
            setDes(articles.description);
        }
    }, [articles]);
    return (
        <div>
            {
                articles !== undefined ? <div className={`pop ${popBool ? "ok" : ""}`}>
                        <div className={`back`}>
                            <div className="looper">
                                <div className="qw">{articles.id} - Article</div>
                                <div className="ooper">
                                    <div className="img"
                                         style={{backgroundImage: `url('${MainWithoutApi}/${articles.imageUrl}')`}}></div>
                                    {/*<div className="img"></div>*/}
                                    <div className="right">
                                        <div className="r1"> Title: <span>{articles.title}</span>
                                        </div>
                                        <div className="r1"> Subtitle: <span>{articles.subtitle}</span>
                                        </div>
                                        <div className="r1"> Category: <span>{articles.categoryName}</span></div>
                                        <div className="r1"> Age: <span>{articles.childCategoryName}</span></div>
                                        <div className="r1">
                                            <div>Free : <span>{articles.free === true ? "Yes" : "No"}</span></div>
                                            <div>Active: <span>{articles.active === true ? "Yes" : "No"}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="des">
                                    <div className="tit">Description:</div>


                                    <Editor
                                        className="edits"
                                        editorState={editorState}
                                        toolbar={{
                                            options: [],
                                        }}
                                    />

                                </div>
                                <div className="tgg">
                                    <div className="tit">Tags:</div>
                                    <div className="tags">
                                        {articles.tags && articles.tags.map((i, k) => {
                                            return (
                                                <div className="tgs">{i.name}</div>
                                            )
                                        })}


                                    </div>
                                </div>
                                <div className="audi">
                                    <div className="tit">
                                        <div className="titi">
                                            Audio:
                                        </div>

                                        <audio controls type="audio/*"
                                               src={`${MainWithoutApi}/${articles.audioLink}`}>

                                        </audio>
                                    </div>
                                </div>
                                <div className="more">
                                    <div className="edit" onClick={() => handleEdit()}>
                                        edit
                                    </div>
                                    <div className="del" onClick={() => handleDelete(articles.id)}>
                                        delete
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    popBool: state.auth.artBool,
    popId: state.auth.artId,
    articles: state.oneArt,
    pId:state.pagination.article
});

export default connect(mapStateToProps, {popUpHandle, editHandle, deleteArticles,getArticles})(Pop);
