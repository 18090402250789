import React, {useState} from 'react';
import './auth.scss'
import {connect} from "react-redux";
import {authentication} from "../../actions/auth";
import {useNavigate} from "react-router";

function Auth({authentication}) {
    const history = useNavigate()
    const [data, setData] = useState({
        username: "",
        password: ""
    })
    const handle = (e) => {
        e.preventDefault();
        authentication(data, history)
    }
    return (
        <form onSubmit={handle}>
            <div className="auth">
                <div className="sa">
                    <div className="one">
                        <input placeholder="username" type="text" onChange={event => setData({
                            ...data,
                            username: event.target.value
                        })}/>
                    </div>
                    <div className="one">
                        <input placeholder="password" type="password" onChange={event => setData({
                            ...data,
                            password: event.target.value
                        })}/>
                    </div>
                    <div className="two">
                        <button type="submit" className="input">
                            LOGIN
                        </button>
                    </div>

                </div>
            </div>
        </form>
    );
}


const mapStateToProps = ({auth: {access}}) => ({access});

export default connect(mapStateToProps, {authentication})(Auth);