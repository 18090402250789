import React from 'react';
import './header.scss'
import {FiMenu} from "react-icons/fi";
import {IoMdLogIn} from "react-icons/io";
import {connect} from "react-redux";
import {logout} from "../../actions/auth";

function Header({setBool, bool,logout}) {
    return (
        <div className='header'>
            <div className={`header__bur header__bur--display ${!bool ? "br" : ""}`} onClick={() => setBool(!bool)}>
                <FiMenu/>
            </div>
            <div className="header__login" onClick={() => logout()}>
                <IoMdLogIn/>
                <div>
                    Log out
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({auth: {access}}) => ({access});

export default connect(mapStateToProps, {logout})(Header);
