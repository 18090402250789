import {
    CREATE_AGES,
    UPDATE_AGES,
    GET_AGES
} from '../../constants/actionTypes';

const initialState = {
    ages : [],
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_AGES:
            return {
                ...state,
                ages: action.payload
            };
        case CREATE_AGES:
            return state.ages = [...state.ages, action.payload];
        case UPDATE_AGES:
            return state.ages = state.ages.map((age) => (age._id === action.payload._id ? action.payload : age));
        default:
            return state;
    }
};