import {
    FETCH_ALL_CATEGORIES,
    CREATE_CATEGORIES,
    UPDATE_CATEGORIES,
    DELETE_CATEGORIES,
} from '../../constants/actionTypes';

const initialState = {
    categories : undefined
};


export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_CATEGORIES:
            return state.categories = action.payload;
        case CREATE_CATEGORIES:
            return state.categories = [...state.categories, action.payload];
        case UPDATE_CATEGORIES:
            return state.categories = state.categories.map((category) => (category._id === action.payload._id ? action.payload : category));
        case DELETE_CATEGORIES:
            return state.categories = state.categories.filter((category) => category._id !== action.payload);
        default:
            return state;
    }
};