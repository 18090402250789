import {AUTH, EDIT, LOGOUT, POP_UP,POP_UP_VIDEO,POP_UP_BG} from "../constants/actionTypes";
import {auth} from "../api/auth";


export const authentication = (info, history) => async (dispatch) => {
    try {
        const {data} = await auth(info);
        await dispatch({type: AUTH, payload: data});
        await history('/addarticle')
        await localStorage.setItem("token", data.id_token);
    } catch (error) {
        if (!history) {
            alert('Please try again later , there is a problem with server at the moment');
        } else {
            alert("Your login or password is incorrect")
        }
    }
};

export const logout = () => async (dispatch) => {
    try {
        await dispatch({type: LOGOUT});
        await localStorage.clear();
    } catch (error) {
        await console.log(error);
    }
};
export const popUpHandle = (mes) => async (dispatch) => {
    try {
        await dispatch({type: POP_UP, payload: mes});
    } catch (error) {
        await console.log(error);
    }
};
export const popUpVideoHandle = (mes) => async (dispatch) => {
    try {
        await dispatch({type: POP_UP_VIDEO, payload: mes});
    } catch (error) {
        await console.log(error);
    }
};
export const popUpBgHandle = (mes) => async (dispatch) => {
    try {
        await dispatch({type: POP_UP_BG});
    } catch (error) {
        await console.log(error);
    }
};
export const editHandle = () => async (dispatch) => {
    try {
        await dispatch({type: EDIT});
    } catch (error) {
        await console.log(error);
    }
};